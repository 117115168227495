// import logo from './logo.svg';
import logo from './Secoterra.png';
import './App.css';

function App() {
  return (
    // <div className="App">
        <img src={logo} alt="background" style={{width: '100%'}}/>
    // </div>
  );
}

export default App;
